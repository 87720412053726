:root {
	/*  NEW COLORS VARS BY OI DESIGN SYSTEM CORE LIBRARY v1.7 

    CAPTION:

	100: Lightest
	200: Light
	300: Mid
	400: Mid Accent
	500: Dark
	600: Darkest

*/
	/* 	BRANDS */
	--color-primary-100: #c2ffca;
	--color-primary-150: #9CFCA7;
	--color-primary-200: #70ff84;
	--color-primary-300: #00d61e;
	--color-primary-400: #00a300;
	--color-primary-410: #009e0c;
	--color-primary-500: #006b00;
	--color-primary-600: #003d00;
	--color-secondary-100: #fffac2;
	--color-secondary-200: #fff470;
	--color-secondary-300: #fbd84b;
	--color-secondary-400: #c9ad3d;
	--color-secondary-500: #7c6728;
	--color-secondary-600: #3e3414;

	/* 	VERTICAL BRANDS */
	--color-smarthouse-100: #dbf9e7;
	--color-smarthouse-200: #a5f0c4;
	--color-smarthouse-300: #4be188;
	--color-smarthouse-400: #3db46d;
	--color-smarthouse-500: #2d8752;
	--color-smarthouse-600: #143d25;
	--color-entretainment-100: #edf9d0;
	--color-entretainment-200: #d9f490;
	--color-entretainment-300: #a5e114;
	--color-entretainment-400: #8ac111;
	--color-entretainment-500: #6f9b05;
	--color-entretainment-600: #2a3b02;
	--color-finance-100: #deefd1;
	--color-finance-200: #b1da95;
	--color-finance-300: #6fb63f;
	--color-finance-400: #4a792a;
	--color-finance-500: #2e4c1a;
	--color-finance-600: #1c2d10;
	--color-education-100: #cceee8;
	--color-education-200: #80d5c7;
	--color-education-300: #2cbfa3;
	--color-education-400: #04a082;
	--color-education-500: #006755;
	--color-education-600: #003d32;
	--color-health-100: #ffeadb;
	--color-health-200: #ffcaa6;
	--color-health-300: #ff954d;
	--color-health-400: #e27227;
	--color-health-500: #ad4d0b;
	--color-health-600: #4d2205;
	--color-energy-100: #fff1d0;
	--color-energy-200: #fedc89;
	--color-energy-300: #fdb913;
	--color-energy-400: #f4a608;
	--color-energy-500: #a26402;
	--color-energy-600: #513201;

	/* 	FUNCTIONAL */
	--color-neutral-90: #f9f9f9;
	--color-neutral-94: #F1F1F1;
	--color-neutral-95: #f2f2f2;
	--color-neutral-100: #f5f5f5;
	--color-neutral-200: #e0e0e0;
	--color-neutral-225: #DBDBDB;
	--color-neutral-250: #cccccc;
	--color-neutral-300: #b8b8b8;
	--color-neutral-399: #909090;
	--color-neutral-400: #8f8f8f;
	--color-neutral-450: #595959;
	--color-neutral-500: #3d3d3d;
	--color-neutral-550: #303030;
	--color-neutral-580: #282828;
	--color-neutral-600: #1f1f1f;
	--color-neutral-650: #121212;
	--color-success-100: #d9ece3;
	--color-success-150: #C2FFCA;
	--color-success-200: #72d489;
	--color-success-300: #34ad51;
	--color-success-400: #24803a;
	--color-success-500: #1b602b;
	--color-success-600: #0e2f16;
	--color-warning-100: #fffac2;
	--color-warning-200: #fff470;
	--color-warning-300: #fbd84b;
	--color-warning-400: #c9ad3d;
	--color-warning-500: #7c6728;
	--color-warning-600: #3e3414;
	--color-error-100: #fbdcda;
	--color-error-200: #f5a8a3;
	--color-error-300: #ea3f34;
	--color-error-400: #b91e13;
	--color-error-500: #6f120c;
	--color-error-600: #370906;
	--color-information-100: #cce8f5;
	--color-information-200: #87c9e8;
	--color-information-300: #65bae1;
	--color-information-400: #2899cd;
	--color-information-500: #144c66;
	--color-information-600: #0a2633;

	/* 	CHARTS */
	--color-pink-100: #ffe5fb;
	--color-pink-200: #ffc2f6;
	--color-pink-300: #e165d5;
	--color-pink-400: #cd27bc;
	--color-pink-500: #891a7d;
	--color-pink-600: #440d3f;
	--color-purple-100: #e7dcf9;
	--color-purple-200: #c3a8f0;
	--color-purple-300: #9363e3;
	--color-purple-400: #6525d0;
	--color-purple-500: #43198b;
	--color-purple-600: #220c45;


	// full colors
	--color-black-full: #000;
	--color-white-full: #fff;

	//project borders
	--carousel-card-border-light: none;
	--carousel-card-border-dark: 2px solid var(--color-neutral-400);

	--card-lojaonline-border-light: none;
	--card-lojaonline-border-dark: 1px solid var(--color-neutral-100);
}

.dark {
	// GLOBALS
	--newhome-section-contrast-border-height: 1px;
	--newhome-light-gray-background: var(--color-black-full);
	--newhome-gray-text: var(--color-white-full);
	--newhome-gray-500-text: var(--color-white-full);
	--newhome-semi-black: var(--color-white-full);
	--newhome-semi-black-background: var(--color-black-full);
	--newhome-max-contrast-background: var(--color-black-full);
	--newhome-green-contrast: var(--color-primary-200);
	--newhome-grayscale-background-before: 1;
	--newhome-green-contrast-600: var(--color-primary-200);

	// JOICE
	--newhome-joice-green-color:  var(--color-black-full);
	--newhome-joice-semi-black-color: var(--color-black-full);
	--newhome-joice-cta-hover: var(--color-black-full);

	// OISAUDE E ENERGIA COLOR
	--newhome-oi-saude-text-color: var(--color-primary-200);
	--newhome-oi-energia-text-color: var(--color-primary-200);
	
	// NOTICIAS OI
	--newhome-oinews-cta-green-color: var(--color-black-full);
	--newhome-transparent-and-green-cta: var(--color-primary-200);
	--newhome-oinews-topic-flag-1-color: var(--color-white-full);
	--newhome-oinews-topic-flag-2-color: var(--color-white-full);
	--newhome-oinews-topic-flag-3-color: var(--color-white-full);
	--newhome-oinews-arrow-filter: brightness(0) saturate(100%) invert(81%) sepia(81%) saturate(371%) hue-rotate(58deg) brightness(100%) contrast(102%);
	--newhome-oinews-white-topic-flag: var(--color-neutral-600);

	// BANNER HERO
	--newhome-banner-hero-background-gradient: var(--color-black-full);


	// FOOTER
	--global_information_background: var(--color-neutral-600);
	--global_information_text: var(--color-white-full);
	--global_information_line-divider: var(--color-white-full);
	--global_information_link-colored: var(--color-primary-200);
	--global_information_link-uncolored: var(--color-white-full);
	
	--global_footer_background: var( --color-black-full);
	--global_footer_title-menu: var(--color-primary-200);
	--global_footer_title-menu-item: var(--color-white-full);
	--global_footer_title-menu-item_border-mobile: var(--color-neutral-400);
	--global_footer_social-icon-background: var(--color-neutral-600);
	--global_footer_social-icon-border: var(--color-neutral-500);
	
	--global_footer-cta-filled_background: var(--color-primary-200);
	--global_footer-cta-filled_text: var(--color-black-full);
	--global_footer-cta-stroke_border: var(--color-smarthouse-300);
	--global_footer-cta-stroke_border: var(--color-smarthouse-300);
	
	--global_footer-copyright_text: var(--color-white-full);
	
	// HEADER
	--newhome-header-acessibility-border: var(--color-information-400);
	--newhome-header-regionalization-border: var(--color-neutral-200);
	--newhome-header-regionalization-text: var(--color-neutral-100);
	--newhome-whatsapp-button-border: var(--color-primary-200);
	--newhome-whatsapp-button-background: var(--color-black-full);
	--newhome-sign-fiber-text: var(--color-secondary-300);
	--newhome-sign-fiber-background: var(--color-black-full);
	--newhome-already-client-filter: brightness(0) saturate(100%) invert(81%) sepia(87%) saturate(362%) hue-rotate(57deg) brightness(101%) contrast(101%);
	--newhome-menu-bg-black: var(--color-black-full);
	--newhome-menu-bg-semi-black: var(--color-neutral-600);
	--newhome-menu-input-border: var(--color-neutral-500);
	--newhome-menu-attendance-text-color: var(--color-primary-200);
	--newhome-menu-attendance-bg-color: rgba(0,0,0,0);
	--newhome-menu-attendance-border-color: var(--color-primary-200);
	--newhome-menu-signfiber-green-border: var(--color-primary-200);
	--newhome-menu-selected-item: var(--color-primary-200);
	--newhome-white-and-semiblack-bg: var(--color-neutral-600);
	--newhome-regionalization-active-city-color: none;
	--newhome-regionalization-active-city-border: var(--color-primary-200);
	--newhome-modais-bg-mobile: var(--color-neutral-600);
	--newhome-modais-bg-desktop: var(--color-black-full);
	--newhome-signfiber-card-border: var(--color-primary-200);


	// CONTACT  BTN
	--cttbtn-text-color: var(--color-white-full);
	--cttbtn-close-button-color: var(--color-white-full);
	--cttbtn-background-color: var(--color-neutral-500);
	--cttbtn-child-2-background-color: var(--color-neutral-600);
	--cttbtn-child-2-border-top: none;
	--cttbtn-button-text-color: var(--color-white-full);
	--cttbtn-button-primary-background-color: var();
	--cttbtn-secundary-button-border-color: var(--color-primary-200);

	--cttbtnv2-button-border: var(--color-primary-300);
	--cttbtnv2-background: var(--color-black-full);
	--cttbtnv2-title-text: var(--color-white-full);
	--cttbtnv2-anchor: var(--color-primary-300);
	--cttbtnv2-anchor-text: var(--color-black-full);
	--cttbtnv2-headernormal: var(--color-white-full);
	--cttbtnv2-headericon: var(--color-white-full);
	--cttbtnv2-whtsapp-icon: var(--color-primary-300);
	--cttbtnv2-border: solid;
	--cttbtnv2-bordercolor: var(--color-neutral-300);
}

.light {


	// GLOBALS
	--newhome-section-contrast-border-height: 0px;
	--newhome-light-gray-background: var(--color-neutral-100);
	--newhome-gray-text: var(--color-neutral-400);
	--newhome-gray-500-text: var(--color-neutral-500);
	--newhome-semi-black: var(--color-neutral-600);
	--newhome-semi-black-background: var(--color-neutral-600);
	--newhome-max-contrast-background: var(--color-white-full);
	--newhome-green-contrast: var(--color-primary-500);
	--newhome-green-contrast-600: var(--color-primary-400);
	// CUSTOMER SERVICE
	--newhome-customer-service-background-before-desktop: linear-gradient(315deg, rgba(0,163,0,0) 0%, rgba(0,163,49,0.35) 100%);
	--newhome-customer-service-background-before-mobile: linear-gradient(270deg, rgba(0,163,0,0) 0%, rgba(0,163,49,0.35) 100%);
	--newhome-grayscale-background-before: 0;
	
	// CUSTOMER SERVICE - JOICE 
	--newhome-joice-green-color: var(--color-primary-400);
	--newhome-joice-semi-black-color: var(--color-neutral-600);
	--newhome-joice-cta-hover: #086C04;
	
	
	// OISAUDE E ENERGIA COLOR
	--newhome-oi-saude-text-color: var(--color-health-300);
	--newhome-oi-energia-text-color: var(--color-energy-300);
	
	// NOTICIAS OI
	--newhome-oinews-cta-green-color: var(--color-primary-500);
	--newhome-transparent-and-green-cta: rgba(0,0,0,0);
	--newhome-oinews-topic-flag-1-color: var(--color-smarthouse-300);
	--newhome-oinews-topic-flag-2-color: var(--color-information-400);
	--newhome-oinews-topic-flag-3-color: var(--color-entretainment-300);
	--newhome-oinews-arrow-filter: brightness(0) saturate(100%) invert(14%) sepia(71%) saturate(5696%) hue-rotate(99deg) brightness(98%) contrast(102%);
	--newhome-oinews-white-topic-flag: var(--color-white-full);

	// BANNER HERO
	--newhome-banner-hero-background-gradient: linear-gradient(85deg, rgba(0,49,0,1) 0%, rgba(0,0,0,1) 65%);

	
	// FOOTER
	--global_information_background: var(--color-neutral-500);
	--global_information_text: var(--color-neutral-100);
	--global_information_line-divider: var(--color-neutral-200);
	--global_information_link-colored: var(--color-primary-200);
	--global_information_link-uncolored: var(--color-neutral-100 );
	
	--global_footer_background: var(--color-neutral-600);
	--global_footer_title-menu: var(--color-primary-200);
	--global_footer_title-menu-item: var(--color-white-full);
	--global_footer_title-menu-item_border-mobile: var(--color-neutral-400);
	--global_footer_social-icon-background: var(--color-neutral-600);
	--global_footer_social-icon-border: var(--color-neutral-500);
	
	--global_footer-cta-filled_background: var(--color-primary-200);
	--global_footer-cta-filled_text: var(--color-black-full);
	--global_footer-cta-stroke_border: var(--color-smarthouse-300);
	--global_footer-cta-stroke_border: var(--color-smarthouse-300);
	
	--global_footer-copyright_text: var(--color-neutral-400);

	// HEADER
	--newhome-header-acessibility-border: var(--color-neutral-200);
	--newhome-header-regionalization-border: var(--color-neutral-500);
	--newhome-header-regionalization-text: var(---color-neutral-600);
	--newhome-whatsapp-button-border: var(--color-primary-400);
	--newhome-whatsapp-button-background: var(--color-primary-400);
	--newhome-sign-fiber-text: var(--color-neutral-600);
	--newhome-sign-fiber-background: var(--color-secondary-300);
	--newhome-already-client-filter: brightness(0) saturate(100%) invert(35%) sepia(92%) saturate(1251%) hue-rotate(91deg) brightness(95%) contrast(104%);
	--newhome-menu-bg-black: var(--color-neutral-100);
	--newhome-menu-bg-semi-black: var(--color-neutral-100);
	--newhome-menu-input-border: var(--color-neutral-300);
	--newhome-menu-attendance-text-color: var(--color-white-full);
	--newhome-menu-attendance-bg-color: var(--color-primary-400);
	--newhome-menu-attendance-border-color: var(--color-primary-400);
	--newhome-menu-attendance-shadow: drop-shadow(0px -4px 44px rgba(0, 0, 0, 0.4));
	--newhome-menu-signfiber-green-border: var(--color-neutral-100);
	--newhome-menu-selected-item: var(--color-neutral-600);
	--newhome-white-and-semiblack-bg: var(--color-white-full);
	--newhome-regionalization-active-city-color: var(--color-primary-400);
	--newhome-regionalization-active-city-border: var(--color-primary-400);
	--newhome-modais-bg-mobile: var(--color-white-full);
	--newhome-modais-bg-desktop: var(--color-neutral-100);
	--newhome-signfiber-card-border: var(--color-neutral-400);


	// CONTACT BTN
	--cttbtn-text-color: var(--color-black-full);
	--cttbtn-close-button-color: var(--color-neutral-600);
	--cttbtn-background-color: var(--color-white-full);
	--cttbtn-child-2-background-color: var(--color-neutral-94);
	--cttbtn-child-2-border-top: var(--color-neutral-200);
	--cttbtn-button-text-color: var(--color-black-full);
	--cttbtn-secundary-button-border-color: var(--color-neutral-200);

	--cttbtnv2-button-border: var(--color-primary-400);
	--cttbtnv2-background: var(--color-white-full);
	--cttbtnv2-title-text: var(--color-neutral-600);
	--cttbtnv2-anchor: var(--color-primary-400);
	--cttbtnv2-anchor-text: var(--color-white-full);
	--cttbtnv2-headernormal: var(--color-neutral-600);
	--cttbtnv2-headericon: var(--color-neutral-600);
	--cttbtnv2-whtsapp-icon: var(--color-primary-410);
	--cttbtnv2-border: none;
	--cttbtnv2-bordercolor: transparent;
}

body {
	
	background-color: var(--body-background);
}