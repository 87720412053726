.modal-whatsappv2-components__header-icon {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid #E0E0E0;
	padding-bottom: 20px;
	padding-inline: 16px;

	color: var(--cttbtnv2-headericon);

	&__title {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	span {
		font-size: 1rem;
		font-style: normal;
		font-weight: 500;
		// line-height: 150%; /* 24px */
	}

	button {
		background-color: transparent;
		border: none;
		padding: 0;
		font-weight: 900;
		color: inherit;
	}
}