.modal-whatsappv2-components__header-normal {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-inline: 16px;

	color: var(--cttbtnv2-headernormal);

	span {
		font-size: 1rem;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		/* 24px */
	}

	button {
		background-color: transparent;
		border: none;
		padding: 0;
		font-weight: 900;
		color: inherit;
	}
}