// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@media all and (min-resolution:.001dpcm) {
	// Simplon BP
	@font-face {
		font-family: 'Simplon BP';
		src: url(../assets/fonts/Simplon-BP/WOFF/SimplonBP-Light.woff) format('woff'),
		url(../assets/fonts/Simplon-BP/OTF/SimplonBP-Light.otf) format('otf'),
		url(../assets/fonts/Simplon-BP/TTF/SimplonBP-Light.ttf) format('truetype');
		font-weight: 300;
		font-display: swap;
	}

	@font-face {
		font-family: 'Simplon BP';
		src: url(../assets/fonts/Simplon-BP/WOFF/SimplonBP-Regular.woff)format('woff'),
		url(../assets/fonts/Simplon-BP/OTF/SimplonBP-Regular.otf) format('otf'),
		url(../assets/fonts/Simplon-BP/TTF/SimplonBP-Regular.ttf) format('truetype');
		font-weight: 400;
		font-display: swap;
	}

	@font-face {
		font-family: 'Simplon BP';
		src: url(../assets/fonts/Simplon-BP/WOFF/SimplonBP-Medium.woff)format('woff'),
		url(../assets/fonts/Simplon-BP/OTF/SimplonBP-Medium.otf)format('otf'),
		url(../assets/fonts/Simplon-BP/TTF/SimplonBP-Medium.ttf)format('truetype');
		font-weight: 500;
		font-display: swap;
	}

	// Simplon Oi
	@font-face {
		font-family: 'Simplon Oi';
		src: url(../assets/fonts/Simplon-Oi-Headline/OTF/SimplonOi-2018.otf);
		font-weight: 400;
		font-display: swap;
	}

	// Open Sans
	@font-face {
		font-family: "Open Sans";
		src: url(../assets/fonts/OpenSans-Light.ttf);
		font-weight: 300;
		font-display: swap;
	}
	
	@font-face {
		font-family: "Open Sans";
		src: url(../assets/fonts/OpenSans-Regular.ttf);
		font-weight: 400;
		font-display: swap;
	}
	
	@font-face {
		font-family: "Open Sans";
		src: url(../assets/fonts/OpenSans-SemiBold.ttf);
		font-weight: 600;
		font-display: swap;
	}
	
	@font-face {
		font-family: "Open Sans";
		src: url(../assets/fonts/OpenSans-Bold.ttf);
		font-weight: 700;
		font-display: swap;
	}

	@font-face{
		font-family: 'Open Sans-Bold';
		font-style: normal;
		font-weight: 700;
		src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap')
	}

	// -- Open Sans
}