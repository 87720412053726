section.oiFibraX__section{
    background-color:  var(--color-black-full);
    background: url(../assets/images/oifibrax/oifibrax-background-mobile.webp);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-bottom: none;
}
section.oiFibraX__section::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: grayscale(var(--newhome-grayscale-background-before));
}
@media screen and (min-width: 500px) {
    section.oiFibraX__section{
        background: url(../assets/images/oifibrax/oifibrax-background-tablet.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
    }
}
@media screen and (min-width: 1366px) {
    section.oiFibraX__section{
        background: url(../assets/images/oifibrax/oifibrax-background-desktop.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.oiFibraX__flexbox{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 199px 0px 65px 0px;
    max-width: 500px;
    margin: 0px auto;
    
}

.oiFibraX__wifi-home-text{
    color: var(--color-neutral-100);
    font-size: 1.25rem;
    line-height: 120%;
    margin-bottom: 8px;
}

.oiFibraX__principalText{
    font-size: 2rem;
    line-height: 125%;
    color: var(--color-white-full);
    font-weight: 700;
    margin-bottom: 8px;
}
.oiFibraX__principalText b{
    color: var(--color-primary-200);
}

.oiFibraX__subtitle{
    color: var(--color-white-full);
    font-size: 1.25rem;
    line-height: 120%;
    font-weight: bold;
    max-width: 290px;
    margin-bottom: 16px;
}

.oiFibraX__description{
    color: var(--color-neutral-100);
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 24px;
}
.oiFibraX__description br{
    display: none;
}

.oiFibraX__ctaArea{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.oiFibraX__fibrax-CTA, .oiFibraX__fibraxPremium-CTA{
    max-width: 293px;
    gap: 8px;
}

.oiFibraX__fibrax-CTA{
    background-color: var(--color-primary-200);
    color: var(--color-neutral-600);
}

.oiFibraX__fibraxPremium-CTA{
    border: 1px solid var(--color-primary-200);
    color: var(--color-primary-200);
}



@media screen and (min-width: 768px) {
    .oiFibraX__flexbox{
        padding: 115.5px 0px;
        max-width: none;
        margin: 0;
    }

    .oiFibraX__subtitle{
        max-width: none;
    }

    .oiFibraX__description br{
        display: initial;
    }

    .oiFibraX__ctaArea{
        flex-direction: row;
    }

    .oiFibraX__fibrax-CTA{
        min-width: 224px;
    }
    .oiFibraX__fibraxPremium-CTA{
        min-width: 293px;
    }
}

@media screen and (min-width: 1366px) {
    .oiFibraX__flexbox{
        padding: 199px 0px;
    }

    .oiFibraX__description br{
        display: none;
    }
    .oiFibraX__description{
        max-width: 590px;
    }
}