// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
}

body,
input,
textarea,
button {
	font-family: $primary-font;
	font-weight: 400;
	font-size: 1rem;
}

body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow-x:hidden;
}

button {
	cursor: pointer;
}

img {
	max-width: 100%;
}