.oiSolutions__section{
    background-color: var(--newhome-max-contrast-background) ;
}
.oiSolutions__flexbox{
    display: flex;
    flex-direction: column;
    padding: 64px 0px;
    width: 100%;
}

.oiSolutions__topPartContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    width: 100%;
}

.oiSolutions__topIcons{
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-bottom: 24px;
}
.oiSolutions__topIcons img{
    width: 24px;
    height: 24px;
}

.oiSolutions__title{
    font-size: 2rem;
    line-height: 125%;
    color: var(--newhome-semi-black);
    font-weight: 700;
    margin-bottom: 16px;
    max-width: 225px;
}
.oiSolutions__title b {
    color: var(--newhome-green-contrast);
}

.oiSolutions__subtitle{
    font-size: 1rem;
    line-height: 150%;
    color: var(--newhome-semi-black);
    max-width: 81%;
}


// CAROUSEL AND CARDS
.oiSolutions__carousel-wrapper{
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
    scroll-snap-type: x mandatory   ;
    overflow: scroll;
    scroll-behavior: smooth;
    padding-right: 16px;
    position: relative;
    margin-bottom: 48px;
}
.oiSolutions__carousel-wrapper::-webkit-scrollbar {
    display: none;             
}

.oiSolutions__dotsContainer{
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.oiSolutions__dot{
    transition: all ease 0.2s;
    background-color: var(--newhome-semi-black);
    border: none;
    width: 16px;
    height: 4px;
    opacity: 16%;
    border-radius: 999px;
}
.oiSolutions__dot.active{
    opacity: 100%;
}

.oiSolutions__card{
    overflow: hidden;
    position: relative;
    width: 246px;
    min-width: 246px;
    max-width: 246px;
    height: 320px;
    border-radius: 32px;
    scroll-snap-align: start;
    border: var(--newhome-section-contrast-border-height) solid #FFF;
}

.oiSolutions__cardBackground{
    height: 100%;
    width: 380px;
    max-width: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
}

.oiSolutions__cardContent{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 32px 15px;
    display: flex;
    height: fit-content;
    align-items: center;
    flex-direction: column;
}

.oiSolutions__cardContentIcon{
    width: 100%;
    height: 24px;   
    margin-bottom: 32px;
}

.oiSolutions__cardContentDescription{
    color: var(--color-neutral-200);
    font-size: 1rem;
    line-height: 150%;
    text-align: center;
    margin-bottom: 12px;
    height: 96px;
    max-width: 92%;
}

.oiSolutions__card:nth-child(2) .oiSolutions__cardContentDescription{
    max-width: 89%;
}

a.oiSolutions__cardContentCTA{
    gap: 8px;
    height: 42px;
}
.signStreaming__oiSaudeCTA{
    color: var(--newhome-oi-saude-text-color);
}
.signStreaming__oiEnergiaCTA{
    color: var(--newhome-oi-energia-text-color);
}


@media screen and (min-width: 530px) {
    .oiSolutions__dotsContainer{
        display: none;
    }
    .oiSolutions__carousel-wrapper{
        width: fit-content;
        margin-inline: auto;
        padding-right: 32px;
    }
}

@media screen and (min-width: 768px) {
    .oiSolutions__carousel-wrapper{
        gap: 24px;
    }

    .oiSolutions__title{
        max-width: none;
    }

    .oiSolutions__subtitle{
        max-width: none;
    }

    .oiSolutions__cardContent{
        padding: 44px 15px;
    }

    .oiSolutions__card{
        width: 336px;
        min-width: 336px;
        max-width: 336px;
        height: 416px;
    }

    .oiSolutions__cardBackground{
        width: 420px;
    }

    .oiSolutions__cardContentDescription{
        height: 64px;
        max-width: 239px;
    }
    .oiSolutions__card:nth-child(2) .oiSolutions__cardContentDescription{
        max-width: 239px;
    }
    .oiSolutions__cardContentDescription br{
        display: none;
    }

    .oiSolutions__card:nth-child(2) .oiSolutions__cardBackground{
        left: 40%;
    }
}

@media screen and (min-width: 1366px) {
    .oiSolutions__flexbox{
        flex-direction: row;
        justify-content: space-between;
    }

    .oiSolutions__topPartContainer{
        width: 236px;
        height: fit-content;
        margin-block: auto;
    }

    .oiSolutions__carousel-wrapper{
        padding: 0px;
        margin: 0px;
    }

    .oiSolutions__card {
        width: 424px;
        min-width: 424px;
        max-width: 424px;
        height: 376px;
    }

    .oiSolutions__cardBackground{
        width: 424px;
    }

    .oiSolutions__card:nth-child(2) .oiSolutions__cardBackground{
        left: 50%;
    }
}