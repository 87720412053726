.oiPlace__section {
	background-color: var(--newhome-max-contrast-background);
}
.oiPlace__flexbox {
	display: flex;
	flex-direction: column;
	padding: 64px 0px;
	width: 100%;
}

.oiPlace__title {
	font-size: 1.25rem;
	line-height: 120%;
	color: var(--newhome-gray-500-text);
	letter-spacing: 0.01px;
	font-weight: 400;
	margin-bottom: 8px;
}

.oiPlace__subtitle {
	font-size: 2rem;
	line-height: 125%;
	font-weight: 700;
	color: var(--newhome-semi-black);
	letter-spacing: 0.01px;
	margin-bottom: 24px;
}

.oiPlace__subtitle b {
	color: var(--newhome-green-contrast);
}

.oiPlace__description {
	font-size: 1rem;
	line-height: 150%;
	color: var(--newhome-gray-500-text);
	margin-bottom: 48px;
	max-width: 90%;
}

// CAROUSEL
.oiPlace__carouselContainer {
	width: 100%;
}
.oiPlace__carousel-wrapper {
	width: 100%;
	display: flex;
	gap: 16px;
	align-items: center;
	scroll-snap-type: x mandatory;
	overflow: scroll;
	scroll-behavior: smooth;
	padding-right: 24px;
	position: relative;
	margin-bottom: 48px;
}
.oiPlace__carousel-wrapper::-webkit-scrollbar {
	display: none;
}

.oiPlace__dotsContainer {
	display: flex;
	flex-direction: row;
	gap: 8px;
}
.oiPlace__dot {
	transition: all ease 0.2s;
	background-color: var(--newhome-semi-black);
	border: none;
	width: 16px;
	height: 4px;
	opacity: 16%;
	border-radius: 999px;
}
.oiPlace__dot.active {
	opacity: 100%;
}

.oiPlace__card {
	overflow: hidden;
	position: relative;
	width: 246px;
	min-width: 246px;
	max-width: 246px;
	height: 320px;
	border-radius: 24px;
	scroll-snap-align: start;
	border: var(--newhome-section-contrast-border-height) solid #fff;
	background-color: var(--color-neutral-600);
}

.oiPlace__card::after {
	content: '';
	background: linear-gradient(
		0deg,
		rgba(31, 31, 31, 1) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	position: absolute;
	width: 100%;
	height: 100%;
}

.oiPlace__cardBackground {
	height: 100%;
	// width: 380px;
	max-width: none;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0px);

	top: -30%;
}

.oiPlace__cardContent {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 32px;
	z-index: 1;
	display: flex;
	height: fit-content;
	align-items: center;
	flex-direction: column;
}

.oiPlace__cardContentIconArea {
	display: flex;
	margin-bottom: 16px;
}
.oiPlace__contentTitle {
	color: var(--color-neutral-100);
	font-size: 1.25rem;
	line-height: 120%;
	margin-bottom: 16px;
	font-weight: bold;
}

.oiPlace__cardContentDescription {
	color: var(--color-white-full);
	font-size: 1rem;
	line-height: 150%;
	text-align: center;
	margin-bottom: 16px;
	height: 72px;
}

.oiPlace__cardProductPrice {
	font-size: 1.25rem;
	line-height: 120%;
	font-weight: bold;
	display: block;
}

.oiPlace__cardContentCTA {
	gap: 8px;
	color: var(--color-primary-200);
}

@media screen and (min-width: 768px) {
	.oiPlace__card {
		width: 336px;
		min-width: 336px;
		max-width: 336px;
		height: 416px;
	}
	.oiPlace__card::after {
		display: none;
	}

	.oiPlace__cardBackground {
		top: 0px;
		height: auto;
	}

	.oiPlace__card:nth-child(2) .oiPlace__cardBackground {
		top: -26%;
		width: 336px;
	}

	.oiPlace__carousel-wrapper {
		gap: 24px;
	}

	.oiPlace__cardContent {
		padding: 32px 16px;
	}

	.oiPlace__subtitle {
		max-width: 330px;
	}
	.oiPlace__subtitle br {
		display: none;
	}
	.oiPlace__subtitle b {
		display: inline-flex;
		white-space: nowrap;
		width: 30%;
	}

	.oiPlace__cardContentDescription {
		height: 48px;
	}
	.oiPlace__cardContentDescription br {
		display: none;
	}
}

@media screen and (min-width: 1366px) {
	.oiPlace__card {
		width: 312px;
		min-width: 312px;
		max-width: 312px;
		height: 416px;
	}

	.oiPlace__description {
		margin-bottom: 64px;
	}
	.oiPlace__carousel-wrapper {
		padding: 0px;
		margin-bottom: 0px;
	}

	.oiPlace__card:nth-child(2) .oiPlace__cardBackground {
		top: 0px;
		width: 312px;
	}

	.oiPlace__dotsContainer {
		display: none;
	}
}
