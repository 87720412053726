// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/*
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.max_width_container{
	width: 100%;
	margin: 0px auto;
	padding: 0 24px;

  @media (min-width: 769px) {
    padding: 0 24px;
  }

  // @media (min-width: 1364px) {
  //   width: 1223px;
  //   padding: 0;
  // }

  @media (min-width: 1364px){
    width: 1320px;
    padding:0;
  }

}

.gray-when-contrast{
  filter: grayscale(var(--newhome-grayscale-background-before));
  overflow: hidden;
}

a{
  text-decoration: none;
}

.home-section{
  display: flex;
  flex-direction: row;
}
.home-section{
  border-bottom: var(--newhome-section-contrast-border-height) solid #FFF;
}


.home-cta{
  width: 100%;
  height: 48px;
  border-radius: 999px;
  font-size: 1rem;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.dazzle-hover{
  transition: all ease 0.2s;
}

.dazzle-hover:hover{
  filter: brightness(0.5);
}

.width-container,.width-container-for-carousel {
  display: flex;
  width: 100%;
  max-width: 1320px;
  padding: 0px 16px;
  margin: 0px auto;
}
.width-container-for-carousel{
  padding-right: 0px;
}

@media screen and (min-width: 768px) {
  .width-container, .width-container-for-carousel{
      padding: 0px 36px;
  }
  .width-container-for-carousel{
    padding-right: 0px;
  }
}
@media screen and (min-width: 1366px) {
  .width-container, .width-container-for-carousel{
    padding: 0px;
  }
}
