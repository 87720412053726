.modal-whatsappv2-components__button-green {
	display: flex;
	min-width: 232px;
	height: 48px;
	padding: 2px 16px;
	align-items: center;
	gap: 8px;
	background-color: transparent;

	max-width: 100%;
	margin-inline: 16px;

	border-radius: 999px;
	border: 2px solid var(--cttbtnv2-button-border);

	span {
		color: var(--cttbtnv2-button-border);
		text-align: center;

		font-size: 1rem;
		font-weight: 500;
		line-height: 150%;
		/* 24px */
	}
}